* {
  padding:0;
  margin: 0;
  box-sizing: border-box;
}


#root{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

main{
  width:100%;  
  display:grid;
  grid-template-columns: repeat(4,1fr);
/*  grid-template-rows: repeat(5,1fr);*/
  justify-items: center;
}
/*main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}*/
#header{
  grid-column: 1/5;
  /*grid-row:1/2;*/
  width: 100%;
  height: 139px;
  background-color: #4503FF;
  counter-reset: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:0;
  margin-bottom: 67px;
}

h1{
  font-family: "Just Another Hand";
  color: #fff;
  font-size: 64px;
}

.box-set{
  list-style-type: none;
  grid-column: 1/5;
  /*grid-row:2/3;*/
  width: 140px;
  height: 140px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap:10px;
  margin-bottom: 63px;
}

.Box{
  width: 40px;
  height: 40px;
  border:none;
  cursor: pointer;
  font-size: 20px;
}

#restart-btn{
  grid-column: 1/5;
  /*grid-row:3/4;*/
  padding: 10.5px 20.25px;
  margin-bottom: 38px;
  height: 60px;
}
/*#players-container{
  width:100%;
  height: 129px;
  display: flex;
  flex-direction: row;
}*/
.player-box{
  width: 100%;
  border:2px solid transparent;
  /*grid-row:4/5;*/
  display: grid;
  grid-template-columns: repeat(2,1fr);
  padding: 20px 50px 13px 34px;
}
#I{
  grid-column: 1/3;
}

#II{
  grid-column: 3/5;
}
.player-box h2,.player-box h3{
  font-family: "Just Another Hand";
  font-size: 20px;
  grid-column: 1/3;
  text-align: center;
}

.player-box p{
  font-family: "Just Another Hand";
  font-size: 16px;
  text-align: center;
}


footer{
  grid-column: 1/5;
  /*grid-row:5/7;*/
  font-family: "Inter";
  background-color: #787777;
  color: #fff;
  font-size: 10px;
  width: 100%;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
  position:fixed;
  bottom: 0;
}

footer a{
  color:#fff;
  padding-left:5px;
  text-decoration: none;
}

@media only screen and (min-width:1440px){
  main{
    height:100vh;
    position: relative;
  }
  #header{
    height:186px;
    margin-bottom:159px;
  }
  h1{
    font-size: 90px;
  }
  .Box{
    width:100px;
    height:100px;
    font-size: 50px;
  }
  .box-set{
    width:356px;
    height:356px;
    margin-bottom:56px;
  }
  .player-box{
    position: absolute;
    height: 695px;
    width:338px;
    top:186px;
    padding: 84px 81px 147px 50px;
    border:3px solid transparent;
  }
  .player-box h2,.player-box h3{
    font-size: 85px;
  }
  .player-box p{
    font-size: 30px;
  }

  
  #I{
    left:0;
  }
  #II{
    position: absolute;
    right:0;
  }
  #restart-btn{
    margin-bottom:111px;
  }

  footer{
    height:90px;
    font-size: 20px;
    opacity:0.8;
  }

}